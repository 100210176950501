// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-product-page-product-page-tsx": () => import("./../src/components/product-page/product-page.tsx" /* webpackChunkName: "component---src-components-product-page-product-page-tsx" */),
  "component---src-components-vacancy-page-vacancy-page-tsx": () => import("./../src/components/vacancy-page/vacancy-page.tsx" /* webpackChunkName: "component---src-components-vacancy-page-vacancy-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-vocations-tsx": () => import("./../src/pages/vocations.tsx" /* webpackChunkName: "component---src-pages-vocations-tsx" */)
}

